const Font = {
  family: {
    main: "Inter, sans-serif",
  },
  weight: {
    normal: 400,
  },
  baseSize: 16,
}

export { Font }
