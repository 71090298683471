import React from "react"
import { FooterContainer, Copyright } from "./index.style"

const Footer = () => (
  <FooterContainer>
    <Copyright>
      Copyright © {new Date().getFullYear()} Jacob D’Rozario
    </Copyright>
  </FooterContainer>
)

export { Footer }
