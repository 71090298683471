import { Border } from "./border"
import { Color } from "./color"
import { Font } from "./font"
import { Layout } from "./layout"
import {
  pxToRem,
  pxToVw,
  megaClamp,
  focusAccessible,
  linkHoverAnimation,
} from "./utils"

const Style = {
  border: Border,
  color: Color,
  font: Font,
  pxToRem: pxToRem,
  pxToVw: pxToVw,
  megaClamp: megaClamp,
  focusAccessible: focusAccessible,
  linkHoverAnimation: linkHoverAnimation,
  layout: Layout,
}

export { Style }
