import { Border } from "./border"
import { Font } from "./font"
import { Color } from "./color"
import { Layout } from "./layout"

const pxToRem = (pixelValue, multipler = 1) => {
  const pixelInt = pixelValue === "default" ? Font.baseSize : pixelValue

  return `${(pixelInt * multipler) / Font.baseSize}rem`
}

const pxToVw = (viewportWidth: number, sizeInPx: number): string => {
  const percent = viewportWidth / 100
  const calculatedValue = sizeInPx / percent

  return calculatedValue % 1 == 0
    ? calculatedValue.toString() + "vw"
    : calculatedValue.toFixed(3).toString() + "vw"
}

const megaClamp = (
  minGridUnitMultiplier: number,
  maxGridUnitMultiplier: number,
  maxWidth: number = Layout.maxWidth.contentWrapper
) => {
  return `clamp(${pxToRem(Layout.gridUnit, minGridUnitMultiplier)},
    ${pxToVw(maxWidth, Layout.gridUnit * maxGridUnitMultiplier)},
    ${pxToRem(Layout.gridUnit, maxGridUnitMultiplier)}
  )`
}

const focusAccessible = () => {
  return `
    &:focus {
      outline-width: 0;
      box-shadow: ${Color.brand} 0 0 0 0.25rem;
      transition: box-shadow 0.2s ease 0s;

      &:active {
        outline-width: 0;
        box-shadow: none;
      }
    }
  `
}

const linkHoverAnimation = () => {
  return `
    &:hover {
      &:after {
        transform: initial;
      }
    }

    &:after {
      content: "";
      background-image: initial;
      background-color: currentcolor;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: ${pxToRem(Layout.gridUnit, 0.25)};
      color: ${Color.brand};
      transform-origin: left;
      transition: transform 400ms ease-out;
      transform: scaleX(0);
  }
`
}

export { pxToRem, pxToVw, megaClamp, focusAccessible, linkHoverAnimation }
