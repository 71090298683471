import React from "react"
import { GlobalStyle } from "./index.style"
import { Footer } from "../Footer/index"
import { Header } from "../Header/index"

interface Props {
  children: React.ReactNode
  headerBorderBottom?: boolean
}

const Layout: React.FC<Props> = ({ children, headerBorderBottom }) => {
  return (
    <>
      <GlobalStyle />
      <Header borderBottom={headerBorderBottom} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
