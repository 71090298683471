import styled from "styled-components"
import { Style } from "../../style"

interface HeaderContainerProps {
  borderBottom: boolean
}

interface HeaderLinkProps {
  active: boolean
}

const HeaderContainer = styled.header<HeaderContainerProps>`
  margin-top: ${Style.megaClamp(5, 8)};
  display: flex;
  max-width: ${Style.layout.maxWidth.main}px;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-width: 0;
  border-style: solid;
  border-color: ${Style.color.brand};
  border-bottom-width: ${props =>
    props.borderBottom ? Style.pxToRem(Style.border.width) : 0};

  @media screen and (min-width: ${Style.layout.breakpoint.sm}px) {
    flex-direction: row;
    justify-content: space-between;
  }

  padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 3)};

  @media screen and (min-width: ${Style.layout.breakpoint.med}px) {
    padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 4)};
  }

  @media screen and (min-width: ${Style.layout.breakpoint.lg}px) {
    padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 6)};
  }
`

const HeaderLink = styled.a<HeaderLinkProps>`
  color: ${Style.color.brand};
  text-decoration: none;
  font-family: ${Style.font.family.main};
  font-weight: ${Style.font.weight.normal};
  position: relative;
  font-size: ${Style.megaClamp(2, 2.5)};
  line-height: ${Style.megaClamp(3, 4)};

  margin-left: ${Style.pxToRem(Style.layout.gridUnit, 3)};

  &:first-of-type {
    margin-left: 0;
  }

  @media screen and (min-width: ${Style.layout.breakpoint.sm}px) {
    margin-left: ${Style.pxToRem(Style.layout.gridUnit, 4)};
  }

  @media screen and (min-width: ${Style.layout.breakpoint.med}px) {
    margin-left: ${Style.pxToRem(Style.layout.gridUnit, 5)};
  }

  @media screen and (min-width: ${Style.layout.breakpoint.lg}px) {
    margin-left: ${Style.pxToRem(Style.layout.gridUnit, 6)};
  }

  ${Style.focusAccessible()};
  ${Style.linkHoverAnimation()};

  ${props =>
    props.active
      ? ` &:after {
        content: "";
        background-image: initial;
        background-color: currentcolor;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: ${Style.pxToRem(Style.layout.gridUnit, 0.25)};
        color: ${Style.color.brand};
        transform: initial;
    }`
      : ``};
`

const LinksContainer = styled.nav`
  display: flex;
`

const SiteTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre;
  color: ${Style.color.foreground};
  font-family: ${Style.font.family.main};
  font-weight: ${Style.font.weight.normal};
  font-size: ${Style.megaClamp(2, 2.5)};
  line-height: ${Style.megaClamp(3, 4)};
  display: flex;

  @media screen and (max-width: ${Style.layout.breakpoint.sm - 1}px) {
    margin-bottom: ${Style.pxToRem(Style.layout.gridUnit, 1)};
  }
`

const SiteLink = styled.a`
  text-decoration: none;
  color: ${Style.color.foreground};
  position: relative;

  ${Style.focusAccessible()};
  ${Style.linkHoverAnimation()};
`

export { HeaderLink, LinksContainer, HeaderContainer, SiteTitle, SiteLink }
