import { createGlobalStyle } from "styled-components"
import { Style } from "../../style"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${Style.color.background};
    color: ${Style.color.foreground};
    font-size: ${Style.font.baseSize}px;
    font-family: ${Style.font.family.main};
    font-weight: ${Style.font.weight.normal};
    margin-left: ${Style.pxToRem(Style.layout.gridUnit, 2)};
    margin-right: ${Style.pxToRem(Style.layout.gridUnit, 2)};
  
    @media screen and (min-width: ${Style.layout.breakpoint.sm}px) {
      margin-left: ${Style.pxToRem(Style.layout.gridUnit, 4)};
      margin-right: ${Style.pxToRem(Style.layout.gridUnit, 4)};
    }

    @media screen and (min-width: ${Style.layout.breakpoint.med}px) {
      margin-left: ${Style.pxToRem(Style.layout.gridUnit, 6)};
      margin-right: ${Style.pxToRem(Style.layout.gridUnit, 6)};
    }

    @media screen and (min-width: ${Style.layout.breakpoint.lg}px) {
      margin-left: ${Style.pxToRem(Style.layout.gridUnit, 10)};
      margin-right: ${Style.pxToRem(Style.layout.gridUnit, 10)};
    }

    * {
      box-sizing: border-box;
      margin: 0;
    }
  }
`

export { GlobalStyle }
