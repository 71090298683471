const Layout = {
  gridUnit: 8,
  maxWidth: {
    contentWrapper: 688,
    main: 960,
  },
  breakpoint: {
    sm: 479,
    med: 767,
    lg: 959,
  },
}

export { Layout }
