import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  HeaderLink,
  HeaderContainer,
  LinksContainer,
  SiteTitle,
  SiteLink,
} from "./index.style"

interface Props {
  borderBottom?: boolean
}

const Header: React.FC<Props> = ({ borderBottom }) => {
  const data = useStaticQuery(graphql`
    {
      allMenuLinksYaml(sort: { fields: order }) {
        nodes {
          link_text
          url
          id
        }
      }
    }
  `)

  const makeMenuLinks = () => {
    return data.allMenuLinksYaml.nodes.map(link => {
      return (
        <HeaderLink href={`${link.url}`} key={link.id}>
          {link.link_text}
        </HeaderLink>
      )
    })
  }

  return (
    <HeaderContainer borderBottom={borderBottom}>
      <SiteTitle>
        <SiteLink href="/">Jacob D’Rozario</SiteLink>
      </SiteTitle>
      <LinksContainer>{makeMenuLinks()}</LinksContainer>
    </HeaderContainer>
  )
}

export { Header }
