import styled from "styled-components"
import { Style } from "../../style"

const FooterContainer = styled.footer`
  max-width: ${Style.layout.maxWidth.main}px;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${Style.pxToRem(Style.layout.gridUnit, 2)};
  padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 6)};

  @media screen and (min-width: ${Style.layout.breakpoint.med}px) {
    padding-top: ${Style.pxToRem(Style.layout.gridUnit, 4)};
    padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 8)};
  }

  @media screen and (min-width: ${Style.layout.breakpoint.lg}px) {
    padding-top: ${Style.pxToRem(Style.layout.gridUnit, 6)};
    padding-bottom: ${Style.pxToRem(Style.layout.gridUnit, 10)};
  }
`

const Copyright = styled.p`
  font-family: ${Style.font.family.main};
  color: ${Style.color.foreground};
  font-weight: ${Style.font.weight.normal};
  text-align: left;
  font-size: ${Style.megaClamp(1.5, 2)};
  line-height: ${Style.megaClamp(2.5, 3)};

  @media screen and (min-width: ${Style.layout.breakpoint.sm}px) {
    text-align: center;
  }
`

export { FooterContainer, Copyright }
